<template>
  <div v-if="isLoading" style="cursor:progress;">
    <div class="main-text" v-if="!isFailedLoadingPage">
      <h3 class="is-size-3">Memuat Halaman <em>(Loading the page)</em>....</h3>
      <p v-if="isLoadingPageTooLong">
        <span style="color: orange;">
          Mohon tunggu, sepertinya membutuhkan proses lebih lama untuk memuat data. <em>(Please wait, it seems to require a longer process to load data.)</em>
        </span>
      </p>
      <p class="has-text-grey-light">{{ appNameVersion }}</p>
    </div>
    <div class="main-text" v-else>
      <h3 class="is-size-3" style="color:red;">Gagal Memuat Halaman <em>(Failed to load the page)</em>.</h3>
      <div class="has-text-danger">
        Terjadi kesalahan <em>(Something went wrong)</em>
        <span>: {{ failedLoadingPageMessage }}</span>
      </div>
      <div class="py-5">
        <a
          href="javascript:location.reload()"
          class="has-text-success"
          style="text-decoration: underline;"
        >
          klik untuk memuat ulang <em>(Click to reload the page)</em>.
        </a>
      </div>
      <p class="has-text-grey-light">{{ appNameVersion }}</p>
    </div>
    <button id="btn-share-screen" hidden @click="startRecording"></button>
    <button id="btn-full-screen" hidden @click="startFullScreen"></button>
    <button
      id="btn-screen-capture"
      hidden
      @click="createHiddenCameraElement"
    ></button>
  </div>
  <div v-else>
    <div id="section-loader" v-show="isLoadingSection">
      <p>{{ changeSectionMessage }}</p>
    </div>
    <div class="container" @contextmenu="handlerRightClick($event)">
      <div class="wrapper">
        <b-loading v-model="isLoadingConnection"></b-loading>
        <!-- For header -->
        <div class="columns is-multiline is-centered">
          <!-- Header mobile -->
          <div class="is-hidden-desktop header-mobile column is-12">
            <div class="columns is-mobile header-mobile-column">
              <span
                class="column is-4 time-mobile"
                :style="isCountDownPaused ? 'margin-top:3%' : 'margin-top:5%;'"
              >
                <b
                  class="notranslate"
                  :style="isCountDownPaused ? 'color:hsl(0deg, 0%, 80%);' : ''"
                >
                  {{ countdown }}
                </b>
                <span
                  v-if="isCountDownPaused"
                  style="color:hsl(0deg, 0%, 80%);"
                >
                  (Paused)
                </span>
              </span>
              <span class="column is-4 progress-mobile">
                <b-progress
                  class="notranslate"
                  type="is-hcc"
                  :value="progress"
                  show-value
                  format="percent"
                ></b-progress>
              </span>
              <span class="column is-4 btn-next-mobile">
                <b-field grouped position="is-right">
                  <span id="countdown" class="timer notranslate"></span>
                  <b-button
                    type="is-hcc"
                    :disabled="disableNextBtn || isPlayingMedia || !isOnline"
                    @click="validationNextSection()"
                    v-if="progress != 100"
                  >
                    Next
                  </b-button>
                </b-field>
              </span>
            </div>
          </div>
        </div>
        <!-- Header desktop -->
        <div class="is-hidden-mobile header-desktop column is-12">
          <div class="columns header-desktop-column">
            <span class="column is-2">
              <b
                class="is-size-5 notranslate"
                :style="isCountDownPaused ? 'color:hsl(0deg, 0%, 80%);' : ''"
              >
                {{ countdown }}
              </b>
              <span v-if="isCountDownPaused" style="color:hsl(0deg, 0%, 80%);">
                (Paused)
              </span>
            </span>
            <span class="column is-5 bar">
              <b-field grouped position="is-right">
                <b-progress
                  class="notranslate"
                  type="is-hcc"
                  :value="progress"
                  show-value
                  format="percent"
                ></b-progress>
              </b-field>
            </span>
            <span class="column is-5 btn-next">
              <b-field grouped position="is-right">
                <span id="countdown" class="timer notranslate"></span>
                <b-button
                  type="is-hcc"
                  :disabled="disableNextBtn || isPlayingMedia || !isOnline"
                  @click="validationNextSection()"
                  v-if="progress != 100"
                >
                  Next
                </b-button>
              </b-field>
            </span>
          </div>
        </div>
        <!-- For progress -->
        <div v-if="!isNotFound">
          <div class="columns is-multiline" v-if="!isCameraOff">
            <sidebar-info-progress
              v-if="
                activeStep > 0 &&
                  currentSection != undefined &&
                  currentSection.counter
              "
              ref="sidebarInfoProgress"
              :section="currentSection"
              :currentPage="currentPage"
              @change-page="changePage"
              @uncheck-later="saveCheckLaterQuestion($event, true)"
            />

            <div class="contents column is-12" style="padding-bottom: 25px">
              <b-steps
                :has-navigation="false"
                v-model="activeStep"
                :size="size"
              >
                <!-- For take photo -->
                <b-step-item type="is-hcc">
                  <div v-if="photoStart != 'off' && isLoadingSection == false">
                    <div class="column is-12">
                      <label class="label">
                        <b>
                          <b-field grouped position="is-centered">
                            <h1 class="is-size-3">
                              Silakan mengambil foto sebelum melanjutkan ke sesi berikutnya <em>(Please take photos before continuing to next session)</em>
                            </h1>
                          </b-field>
                        </b>
                      </label>
                    </div>
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="photo" v-if="file">
                          <img id="test" v-lazy="file" />
                          <b-field grouped position="is-centered">
                            <b-button type="is-hcc" @click="modalTakePhoto()">
                              <!-- Take a photo -->
                              Ambil Foto <em>(Take a photo)</em>
                            </b-button>
                          </b-field>
                        </div>
                        <div class="photo" v-else>
                          <img src="@/assets/image/user.png" />
                          <b-field
                            grouped
                            position="is-centered"
                            class="btn-take-photo"
                          >
                            <b-button
                              type="is-hcc"
                              size="is-medium"
                              @click="modalTakePhoto()"
                            >
                              Ambil Foto <em>(Take a photo)</em>
                            </b-button>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                    <div class="column is-12">
                      <p
                        class="has-text-grey-light has-text-centered is-size-7"
                      >
                        {{ appNameVersion }}
                      </p>
                    </div>

                    <b-modal :active.sync="isTakePhotoModal" :width="400">
                      <div id="app" class="card web-camera-container">
                        <div class="camera-box" :class="{ flash: isShotPhoto }">
                          <div
                            class="camera-shutter"
                            :class="{ flash: isShotPhoto }"
                          ></div>

                          <video
                            v-show="!isPhotoTaken"
                            ref="camera"
                            :width="450"
                            :height="337.5"
                            autoplay
                          ></video>

                          <canvas
                            v-show="isPhotoTaken"
                            id="photoTaken"
                            ref="canvas"
                            :width="450"
                            :height="337.5"
                          ></canvas>
                        </div>

                        <div
                          v-if="isCameraOpen && !isLoadingCamera"
                          class="camera-shoot"
                        >
                          <button
                            type="button"
                            class="button"
                            @click="takePhoto"
                          >
                            <img
                              src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                            />
                          </button>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                  <div v-else>
                    <div class="column is-12 is-size-4">
                      <p class="column is-12">
                        <strong>Selamat Datang! <em>(Welcome!)</em></strong>
                      </p>
                      <p class="column is-12">
                        Saat ini Anda akan melakukan serangkaian Aktivitas yang hanya diperuntukkan bagi Anda <em>(Currently you will do a series of activities that are only intended for you).</em>
                      </p>
                      <p class="column is-12">
                        Sebelum memulainya, pastikan kembali bahwa Anda memiliki Perangkat, Jaringan, dan Koneksi Internet yang memadai sesuai dengan informasi yang tertera sebelumnya, agar potret diri Anda dapat tergambar secara optimal <em>(Before starting, make sure again that you have adequate devices, networks and internet connections in accordance with previous information, so that your self -portrait can be illustrated optimally)</em>.
                      </p>
                      <p class="column is-12">
                        Click
                        <strong>'Next'</strong>
                        to start the activity.
                      </p>
                    </div>
                    <div class="column is-12">
                      <p class="has-text-grey-light has-text-right is-size-7">
                        {{ appNameVersion }}
                      </p>
                    </div>
                  </div>
                </b-step-item>

                <!-- For section -->
                <!-- <div v-for="(x, idx) in listSection" :key="'test' + idx">
                  TIME: {{ x.time }}
                </div> -->
                <b-step-item
                  v-for="(section, indexSection) in listSection"
                  :key="indexSection"
                >
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <br />
                      <div v-if="section.isUseIntroduction">
                        <div class="box question watermark">
                          <div v-html="checkName(section.introduction)"></div>
                        </div>
                      </div>

                      <ValidationObserver
                        :ref="'formSection-' + (indexSection + 1)"
                      >
                        <form>
                          <template v-if="section.questionList.length > 0">
                            <div
                              v-for="(question,
                              indexQuestion) in section.questionList"
                              class="watermark"
                              :key="indexQuestion"
                              :id="`question-${question.id}`"
                            >
                              <div
                                class="box question watermark"
                                style="margin-top: 20px"
                                v-show="
                                  section.questionToDisplay *
                                    (currentPage - 1) <
                                    question.index &&
                                    section.questionToDisplay * currentPage >=
                                      question.index
                                "
                              >
                                <div
                                  class="columns is-multiline"
                                  @copy="storeProctoringRecord('copy', $event)"
                                  @paste="
                                    storeProctoringRecord('paste', $event)
                                  "
                                  style="margin-bottom: 5px"
                                >
                                  <!-- For question -->
                                  <div
                                    :class="
                                      question.checkLater
                                        ? 'column is-12 mb-5 has-background-warning'
                                        : 'column is-12 mb-5'
                                    "
                                  >
                                    <b>Pertanyaan <em>(Question)</em> #{{ question.index }}</b>
                                    <span
                                      v-if="question.isRequired == true"
                                      class="required"
                                    >
                                      *
                                    </span>
                                    <b-field
                                      class="is-pulled-right"
                                      style="cursor:pointer;"
                                    >
                                      <b-icon
                                        pack="far"
                                        @click="
                                          saveCheckLaterQuestion(question)
                                        "
                                        :icon="
                                          question.checkLater
                                            ? 'check-square'
                                            : 'square'
                                        "
                                      ></b-icon>
                                      <span
                                        class="is-underlined"
                                        @click="
                                          saveCheckLaterQuestion(question)
                                        "
                                      >
                                        Periksa lagi nanti <em>(Check again later)</em>
                                      </span>
                                    </b-field>
                                  </div>
                                  <div class="column is-12">
                                    <!-- For question text -->
                                    <div
                                      class="notranslate"
                                      v-html="question.question"
                                    ></div>
                                    <br />

                                    <div v-if="question.files.length > 0">
                                      <div
                                        v-for="(file,
                                        indexFile) in question.files"
                                        :key="indexFile"
                                        class="question"
                                      >
                                        <div
                                          v-if="file.fileType == 'image'"
                                          style="margin-bottom: 20px"
                                        >
                                          <img
                                            v-lazy="getUrlFile(file.filePath)"
                                            alt="Image"
                                          />
                                          <br />
                                        </div>
                                        <div v-else style="margin-bottom: 20px">
                                          <template
                                            v-if="file.maxPlayedVideo > 0"
                                          >
                                            <template
                                              v-if="
                                                file.maxPlayedVideo >
                                                  file.totalPlayedVideo
                                              "
                                            >
                                              <video-player
                                                class="video-player-box"
                                                width="320"
                                                height="240"
                                                controls="false"
                                                muted="false"
                                                autoplay
                                                @ended="
                                                  endVideo(
                                                    section.id,
                                                    question.id,
                                                    file.id,
                                                    indexSection,
                                                    indexQuestion,
                                                    indexFile
                                                  )
                                                "
                                                :ref="
                                                  'video_' +
                                                    question.index +
                                                    '_' +
                                                    indexSection +
                                                    '_' +
                                                    indexFile
                                                "
                                                :options="
                                                  setOptions(
                                                    getUrlFile(file.filePath)
                                                  )
                                                "
                                              ></video-player>
                                              <b-button
                                                type="is-hcc"
                                                @click="
                                                  playVideo(
                                                    question.index,
                                                    indexSection,
                                                    indexFile
                                                  )
                                                "
                                                style="margin-top: 10px"
                                              >
                                                Play
                                              </b-button>
                                            </template>
                                          </template>
                                          <template v-else>
                                            <video-player
                                              class="video-player-box"
                                              width="320"
                                              height="240"
                                              controls="false"
                                              muted="false"
                                              autoplay
                                              @ended="
                                                endVideo(
                                                  section.id,
                                                  question.id,
                                                  file.id,
                                                  indexSection,
                                                  indexQuestion,
                                                  indexFile
                                                )
                                              "
                                              :ref="
                                                'video_' +
                                                  question.index +
                                                  '_' +
                                                  indexSection +
                                                  '_' +
                                                  indexFile
                                              "
                                              :options="
                                                setOptions(
                                                  getUrlFile(file.filePath)
                                                )
                                              "
                                            ></video-player>
                                            <b-button
                                              type="is-hcc"
                                              @click="
                                                playVideo(
                                                  question.index,
                                                  indexSection,
                                                  indexFile
                                                )
                                              "
                                              style="margin-top: 10px"
                                            >
                                              Play
                                            </b-button>
                                          </template>
                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- For answer -->
                                  <template
                                    v-if="question.questionType == 'essay'"
                                  >
                                    <template>
                                      <div
                                        class="column is-12"
                                        style="margin-bottom: 5px"
                                      >
                                        <b>Jawaban <em>(Answer)</em></b>
                                      </div>
                                      <div class="column is-12">
                                        <!-- For type essay -->
                                        <answer-type-essay
                                          :ref="
                                            question.questionType +
                                              '_' +
                                              question.id
                                          "
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @resend-answer="resendAnswer($event)"
                                        />
                                      </div>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <div
                                      class="column is-12"
                                      style="margin-bottom: 5px"
                                    >
                                      <b>Jawaban <em>(Answer)</em></b>
                                    </div>
                                    <div class="column is-12">
                                      <!-- For type radio button -->
                                      <template
                                        v-if="
                                          question.questionType ==
                                            'radio button'
                                        "
                                      >
                                        <answer-type-radio-button
                                          :ref="
                                            question.questionType +
                                              '_' +
                                              question.id
                                          "
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @resend-answer="resendAnswer($event)"
                                        />
                                      </template>

                                      <!-- For type checkbox -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'checkbox'
                                        "
                                      >
                                        <answer-type-checkbox
                                          :ref="
                                            question.questionType +
                                              '_' +
                                              question.id
                                          "
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @resend-answer="resendAnswer($event)"
                                        />
                                      </template>

                                      <!-- For type linear scale -->
                                      <template
                                        v-else-if="
                                          question.questionType ==
                                            'linear scale'
                                        "
                                      >
                                        <answer-type-linear-scale
                                          :ref="
                                            question.questionType +
                                              '_' +
                                              question.id
                                          "
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @resend-answer="resendAnswer($event)"
                                        />
                                      </template>

                                      <!-- For type star rating -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'star rating'
                                        "
                                      >
                                        <answer-type-star-rating
                                          :ref="
                                            question.questionType +
                                              '_' +
                                              question.id
                                          "
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @resend-answer="resendAnswer($event)"
                                        />
                                      </template>

                                      <!-- For type ranking -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'ranking'
                                        "
                                      >
                                        <answer-type-ranking
                                          :ref="
                                            question.questionType +
                                              '_' +
                                              question.id
                                          "
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @resend-answer="resendAnswer($event)"
                                        />
                                      </template>

                                      <!-- For type matrix -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'matrix'
                                        "
                                      >
                                        <answer-type-matrix
                                          :ref="
                                            question.questionType +
                                              '_' +
                                              question.id
                                          "
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @resend-answer="resendAnswer($event)"
                                          v-if="
                                            question.questionType == 'matrix'
                                          "
                                        />
                                      </template>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </template>
                        </form>
                      </ValidationObserver>

                      <span class="column is-12">
                        <b-pagination
                          :current.sync="currentPage"
                          :total="section.counter"
                          :per-page="section.questionToDisplay"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page"
                          @change="pageScrollTop"
                        ></b-pagination>
                      </span>
                    </div>
                  </div>
                </b-step-item>

                <!-- For thank you page -->
                <b-step-item class="step-thank-you">
                  <h3>{{ thankYouPage.title }}</h3>
                  <div v-html="thankYouPage.description"></div>
                  <br />
                  <br />
                  <br />
                  <p v-if="!isAssessmentFinish">
                    <b>Mohon jangan tutup atau pindah halaman ini <em>(Please don't close or move this page)</em>.</b>
                  </p>

                  <p v-if="countdownRedirect <= 10">
                    Seluruh jawaban Anda telah berhasil tersimpan. Anda akan berpindah secara otomatis dalam
                    <b>{{ countdownRedirect }}</b>
                    detik. Jika tidak berpindah, silahkan
                    <a :href="backlink"><u>klik disini</u></a>
                    untuk kembali ke halaman utama.
                  </p>
                  <p v-else>
                    Sistem sedang menyimpan seluruh jawaban tes Anda.... <em>(Storing all your answers ....) </em>
                    <b-icon
                      size="is-small"
                      style="height: 10px;"
                      icon="circle-notch"
                      custom-class="fa-spin"
                    ></b-icon>
                  </p>
                </b-step-item>
              </b-steps>
            </div>
          </div>
          <div v-else>
            <CameraNotFound />
          </div>
        </div>
        <div v-else>
          <NotFound />
        </div>

        <video
          hidden
          v-show="!isPhotoTaken"
          ref="cameraHidden"
          :width="450"
          :height="337.5"
          autoplay
        ></video>
        <canvas
          hidden
          id="hiddenCanvas"
          ref="canvasHidden"
          :width="450"
          :height="337.5"
        ></canvas>

        <!-- Video offscreen -->
        <video
          id="video"
          style="position: absolute; top: -10000px; left: -10000px"
          autoplay
        ></video>
      </div>
    </div>
    <button id="btn-share-screen" hidden @click="startRecording"></button>
    <button id="btn-full-screen" hidden @click="startFullScreen"></button>
    <button
      id="btn-screen-capture"
      hidden
      @click="createHiddenCameraElement"
    ></button>
  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue'
import CameraNotFound from '@/components/CameraNotFound.vue'
import { mapActions } from 'vuex'

import AnswerTypeEssay from '@/components/V2/Candidat/AnswerTypeEssay.vue'
import AnswerTypeRadioButton from '@/components/V2/Candidat/AnswerTypeRadioButton.vue'
import AnswerTypeLinearScale from '@/components/V2/Candidat/AnswerTypeLinearScale.vue'
import AnswerTypeCheckbox from '@/components/V2/Candidat/AnswerTypeCheckbox.vue'
import AnswerTypeRanking from '@/components/V2/Candidat/AnswerTypeRanking.vue'
import AnswerTypeStarRating from '@/components/V2/Candidat/AnswerTypeStarRating.vue'
import AnswerTypeMatrix from '@/components/V2/Candidat/AnswerTypeMatrix.vue'
import SidebarInfoProgress from '@/components/V2/Candidat/SidebarInfoProgress.vue'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'answer-bank-soal',
  metaInfo() {
    return {
      htmlAttrs: {
        lang: process.env.VUE_APP_LOCALE,
      },
      meta: [{ name: 'google', content: 'notranslate' }],
    }
  },
  components: {
    NotFound,
    CameraNotFound,
    videoPlayer,
    AnswerTypeEssay,
    AnswerTypeRadioButton,
    AnswerTypeLinearScale,
    AnswerTypeCheckbox,
    AnswerTypeRanking,
    AnswerTypeStarRating,
    AnswerTypeMatrix,
    SidebarInfoProgress,
  },
  data() {
    return {
      appNameVersion: null,
      isNotFound: false,
      isCameraOff: false,
      isCameraOpen: false,
      isLoading: true,
      isOnline: true,
      isLoadingPageTooLong: false,
      isFailedLoadingPage: false,
      failedLoadingPageMessage: null,
      isLoadingCamera: false,
      isTakePhotoModal: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isPreview: false,
      isCountDown: false,
      isLoadingSection: false,
      isAssessmentFinish: false,
      isConfirmNextSection: false,

      totalSection: 0,
      sectionIdx: 0,
      seconds: 0,
      countdown: '',
      isCountDownPaused: false,
      size: 'small',
      file: null,
      interval: null,
      ssInterval: null,
      nitWatermark: '',

      watermark: '',
      thankYouPage: {
        title: '',
        description: '',
      },
      activeStep: 0,
      screencaptureTime: 0,
      screenshotTime: 0,
      currentPage: 1,
      listSection: [],

      openTab: 0,
      openTab_: 0,
      dialogOpenTab: false,
      arrayConnectionFailed: [],
      dialogConnectionFailed: false,
      isLoadingConnection: false,

      countdownRedirect: 12,

      userName: '',
      appUrl: '',
      listScreens: [],

      nit: null,
      tesId: null,
      assessmentCode: null,
      timerInterval: null,
      photoStart: 'on',
      companyCode: null,
      hiddenPhoto: 'on',
      hiddenScreenCapture: 'on',
      exitPage: 'on',
      conUnstable: 0,
      conTimer: 0,
      conDialogOpen: false,
      screenShot: 0,
      isPlayingMedia: false,
      disableNextBtn: false,

      backlink: '',
      clickShareScreen: 0,
      errorShareScreen: '',
      failedHiddenCamera: 0,

      errMetrix: false,
    }
  },
  beforeDestroy() {
    // document.removeEventListener('visibilitychange', this.showAlert)
    if (this.exitPage != 'off') {
      window.removeEventListener('blur', this.storeOpenNewTab)
    }
  },

  mounted() {
    this.appNameVersion =
      process.env.VUE_APP_NAME + ' (versi ' + process.env.VUE_APP_VERSION + ')'

    const localVersion = localStorage.getItem('version')
    if (localVersion) {
      const currVersion = localVersion.replaceAll('.', '')
      const nowVersion = process.env.VUE_APP_VERSION.replaceAll('.', '')
      if (parseInt(currVersion) < parseInt(nowVersion)) {
        localStorage.setItem('version', process.env.VUE_APP_VERSION)
        window.location.reload(true)
      }
    } else {
      localStorage.setItem('version', process.env.VUE_APP_VERSION)
    }

    if (this.$route.query.preview && this.$route.query.preview == 'preview') {
      this.isPreview = true
    }
    this.nit = this.$route.params.nit
    this.tesId = this.$route.params.id_tes
    this.assessmentCode = this.$route.params.assessmentCode

    window.onload = async () => {
      await this.checkBrowserCache()
        .then(() => this.init())
        .catch(error => {
          console.log(error)
          let platform =
            process.env.VUE_APP_NAME + ' v' + process.env.VUE_APP_VERSION
          let command = 'CTRL + SHIFT + R'
          if (this.isMac()) command = 'Command (⌘) + SHIFT + R'

          this.$buefy.dialog.alert({
            title: 'Perhatian!',
            message: `Platform versi terbaru sudah tersedia <b>(${platform})</b><br>Silahkan tekan <code>${command}</code> secara bersamaan untuk mendapatkan versi platform terbaru.`,
            type: 'is-warning',
            ariaModal: true,
            canCancel: false,
            onConfirm: () => this.init(),
          })
        })
    }
  },
  computed: {
    progress() {
      if (this.activeStep <= this.listSection.length)
        return (this.sectionIdx / this.totalSection) * 100
      else return ((this.sectionIdx + 1) / this.totalSection) * 100
    },

    currentSection() {
      let activeStep = this.activeStep <= 0 ? 0 : this.activeStep - 1
      return this.listSection[activeStep]
    },

    changeSectionMessage() {
      if (this.currentSection != undefined) {
        if (this.currentSection.isUseTime && this.seconds <= 0) {
          return 'Waktu habis, memuat data (Time runs out, loading data)...'
        } else {
          return 'Memuat data (Loading data)...'
        }
      } else return '...'
    },

    isLastSubsection() {
      return this.activeStep == this.listSection.length
    },

    isLastSection() {
      return this.sectionIdx + 1 >= this.totalSection
    },

    isLastPage() {
      if (this.isAssessmentFinish) {
        return true
      } else {
        return this.isLastSection && this.activeStep > this.listSection.length
      }
    },
  },
  watch: {
    activeStep: async function(val) {
      if (this.isAssessmentFinish) {
        this.decreaseCountdownRedirect()
      } else {
        if (val == 1 && !this.isPreview) {
          if (this.photoStart != 'off' && this.file != null)
            this.sendCandidatPhoto()
        }

        this.updateTimer()
        if (this.isLastPage) {
          try {
            setTimeout(() => {
              this.decreaseCountdownRedirect()
            }, 60000)

            await this.$store.dispatch('sendToCapture', {
              nit: this.nit,
              assessment_code: this.assessmentCode,
              id_tes: this.tesId,
            })
          } catch (error) {
            console.log(error)
          } finally {
            if (this.countdownRedirect == 12) this.decreaseCountdownRedirect()
          }
        } else {
          this.currentPage = 1
        }
      }
    },
  },
  methods: {
    // For map actions
    ...mapActions({
      storeScreen: 'newAnswer/storeScreen',
      fetchAnswers: 'newAnswer/fetchAnswers',
      fetchToken: 'newAnswer/fetchToken',
      validateAllSectionTimer: 'newAnswer/validateAllSectionTimer',
      fetchNextSection: 'newAnswer/fetchNextSection',
      updateAnswer: 'newAnswer/updateAnswer',
      updateCheckLaterQuestion: 'newAnswer/updateCheckLaterQuestion',
      updateAnswerConnectionFailed: 'newAnswer/updateAnswerConnectionFailed',
      updateTime: 'newAnswer/updateTime',
      candidatPhoto: 'newAnswer/candidatPhoto',
      storeHiddenPhoto: 'newAnswer/storeHiddenPhoto',
      storeVideoPlayed: 'newAnswer/storeVideoPlayed',
      storeHiddenScreenCapture: 'newAnswer/storeHiddenScreenCapture',
      storeMonitoringProctoShareScreen:
        'newAnswer/storeMonitoringProctoShareScreen',
    }),

    init() {
      this.fetchData()

      setTimeout(() => {
        if (this.isLoading) this.isLoadingPageTooLong = true
      }, 15000)

      setInterval(() => this.checkInternetConnection(), 1000)
      this.preventScreenShot()
    },

    startFullScreen() {
      let elBody = document.documentElement
      this.requestFullScreen(elBody)
    },

    checkBrowserCache() {
      return new Promise((resolve, reject) => {
        if (process.env.VUE_APP_BASE_URL) {
          let jsFilePaths = Array.prototype.slice
            .apply(document.querySelectorAll('script'))
            .filter(s => s.src)
            .map(s => s.src)
            .filter(s => s.includes(process.env.VUE_APP_BASE_URL))

          if (jsFilePaths.length) {
            let req = new XMLHttpRequest()
            req.open('GET', jsFilePaths[0], false)
            req.send(null)

            let headers = req
              .getAllResponseHeaders()
              .split('\n')
              .map(x => x.split(/: */, 2))
              .filter(x => x[0])
              .reduce((ac, x) => {
                ac[x[0]] = x[1]
                return ac
              }, {})

            if (/no-cache/.test(headers['cache-control']) == false) reject(true)
          }
        }
        resolve(true)
      })
    },

    isMobileTablet() {
      // http://detectmobilebrowsers.com/
      var check = false
      ;(function(a) {
        //eslint-disable-line
        /* eslint-disable */
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    isMac() {
      return navigator.platform.indexOf('Mac') > -1
    },

    isBrowserSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },

    exitHandler() {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        this.$buefy.dialog.alert({
            title: 'Perhatian (Attention)',
            message: 'Izinkan untuk beralih ke Layar Penuh (Allow it to switch to the full screen)',
            type: 'is-warning',
            ariaModal: true,
            closeOnConfirm: true,
            canCancel: false,
            onConfirm: () => {
              setTimeout(() => {
                this.startFullScreen()
              }, 500)
            },
          })
      }
    },

    preventScreenShot() {
      if (document.addEventListener) {
        document.addEventListener('fullscreenchange', this.exitHandler, false)
        document.addEventListener('mozfullscreenchange', this.exitHandler, false)
        document.addEventListener('MSFullscreenChange', this.exitHandler, false)
        document.addEventListener('webkitfullscreenchange', this.exitHandler, false)
      }

      document.onkeydown = e => {
        let FKeys = [
          'F1',
          'F2',
          'F3',
          'F4',
          'F5',
          'F6',
          'F7',
          'F8',
          'F9',
          'F10',
          'F11',
          'F12',
        ]

        if (
          e.metaKey ||
          (e.ctrlKey && e.shiftKey && e.altKey) ||
          (e.ctrlKey && e.shiftKey) ||
          (e.ctrlKey && e.altKey) ||
          (e.ctrlKey && e.key.includes('Digit')) ||
          (e.ctrlKey && e.key.includes('Numpad')) ||
          (e.shiftKey && e.altKey) ||
          (e.shiftKey && e.key.includes('Digit')) ||
          (e.shiftKey && e.key.includes('Numpad')) ||
          (e.altKey && e.key.includes('Digit')) ||
          (e.altKey && e.key.includes('Numpad')) ||
          e.key === 'Escape' ||
          FKeys.includes(e.key)
        ) {
          this.$buefy.modal.open({
            content: `<p style="text-align: center;">Forbidden Action</p>`,
          })
          this.stopPrntScr()
          e.preventDefault()
        }
      }
      document.onkeyup = e => {
        if (e.key == 'Escape') {
          this.$buefy.dialog.alert({
            title: 'Perhatian (Attention)',
            message: 'Izinkan untuk beralih ke Layar Penuh (Allow it to switch to the full screen)',
            type: 'is-warning',
            ariaModal: true,
            closeOnConfirm: true,
            canCancel: false,
            onConfirm: () => {
              setTimeout(() => {
                this.startFullScreen()
              }, 500)
            },
          })
        }
        if (e.key == 'PrintScreen') {
          this.stopPrntScr()
          this.storeProctoringRecord('screen-shot', e)
          this.screenShot++

          let message =
            'Tidak diperkenankan melakukan tangkapan layar <i>(Not allowed to do screenshots)</i>'
          if (this.screenShot >= 3) {
            message =
              '<strong>PEMBERITAHUAN (ANNOUNCEMENT):</strong> Anda telah terdeteksi mencoba beberapa kali melakukan tangkapan layar, silakan login kembali. <i>(You have been detected trying to take a screenshot multiple times, please log in again)</i>. '
          }

          this.$buefy.dialog.alert({
            title: 'Warning',
            message: message,
            type: 'is-warning',
            ariaModal: true,
            onConfirm: () => {
              if (this.screenShot >= 3 && !this.isPreview) {
                location.replace('https://' + this.appUrl + 'logout')
              }
            },
          })
        }
      }
    },
    stopPrntScr() {
      var inpFld = document.createElement('input')
      inpFld.setAttribute('value', '.')
      inpFld.setAttribute('width', '0')
      inpFld.style.height = '0px'
      inpFld.style.width = '0px'
      inpFld.style.border = '0px'
      document.body.appendChild(inpFld)
      inpFld.select()
      document.execCommand('copy')
      inpFld.remove(inpFld)
    },
    checkInternetConnection() {
      this.conTimer++

      if (this.conTimer > 10 && this.conTimer < 60) {
        let checkMod = 60 % this.conTimer

        if (checkMod == 0) {
          this.isOnline = navigator.onLine
          if (this.isOnline == false) {
            this.conUnstable++

            if (this.conUnstable >= 3 && this.conDialogOpen == false) {
              this.conDialogOpen = true

              this.$buefy.dialog.alert({
                title: 'Warning',
                message:
                  'Sepertinya koneksi internet anda sedang tidak stabil (Looks like your internet connection is unstable).',
                type: 'is-warning',
                ariaModal: true,
                onConfirm: () => {
                  this.conTimer = 0
                  this.conUnstable = 0
                  this.conDialogOpen = false
                },
              })
            }
          }
        }
      } else if (this.conTimer > 60) {
        this.conTimer = 0
        this.conUnstable = 0
      }
    },
    // For countdown redirect
    decreaseCountdownRedirect() {
      if (this.countdownRedirect > 0) {
        setTimeout(() => {
          this.countdownRedirect--
          if (this.countdownRedirect <= 0) {
            location.replace('https://' + this.appUrl + 'psikotest')
          }
          this.decreaseCountdownRedirect()
        }, 1000)
      } else {
        location.replace('https://' + this.appUrl + 'psikotest')
      }
    },

    // For check name
    checkName(text) {
      let textData = text.replace('#nama#', this.userName)

      return textData
    },

    pageScrollTop() {
      document.documentElement.scrollTop = 0
    },

    // For play video
    playVideo(index, section, indexFile) {
      let video = this.$refs[
        'video_' + index + '_' + section + '_' + indexFile
      ][0]
      this.isPlayingMedia = true

      video.player.play().catch(() => (this.isPlayingMedia = false))
    },

    // For end video
    async endVideo(
      newSectionId,
      newQuestionId,
      newFileId,
      indexSection,
      indexQuestion,
      indexFile
    ) {
      this.isPlayingMedia = false
      let file = this.listSection[indexSection].questionList[indexQuestion]
        .files[indexFile]

      if (!this.isPreview) {
        let data = {
          nit: this.nit,
          code: this.assessmentCode,
          tesId: Number(this.tesId),
          newSectionId: newSectionId,
          newQuestionId: newQuestionId,
          newFileId: newFileId,
        }

        this.autoSaveTime()
        const response = await this.storeVideoPlayed(data)
        file.totalPlayedVideo = response.data.videoPlayed
      } else {
        file.totalPlayedVideo += 1
      }
    },

    // For set options
    setOptions(src) {
      return {
        // videojs options
        muted: false,
        controls: false,
        width: 320,
        height: 240,
        language: 'en',
        sources: [
          {
            type: 'video/mp4',
            src: src,
          },
        ],
      }
    },

    // For background url file link
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },

    // For right click disabled
    handlerRightClick(event) {
      event.preventDefault()
    },

    // For validation next section
    validationNextSection() {
      if (!this.isPlayingMedia) {
        this.disableNextBtn = true
        if (this.activeStep <= this.listSection.length) {
          if (
            this.activeStep == 0 || // welcome section
            this.currentSection == undefined ||
            (this.currentSection != undefined &&
              this.currentSection.type == 'introductionTest')
          ) {
            this.nextSection()
          } else {
            this.popUpMoveToNextSection()
          }
        } else this.popUpMoveToNextSection()
      } else {
        this.$buefy.dialog.alert({
          title: 'Perhatian (Attention)!',
          confirmText: 'Ok',
          message:
            'Ada Video/Audio yang terlewat. Silakan putar terlebih dahulu sampai habis (There is a video/audio that is missed. Please turn it first until it runs out).',
          type: 'is-danger',
          ariaModal: true,
          closeOnConfirm: true,
          canCancel: false,
        })
      }
    },

    // For popup move to next section
    popUpMoveToNextSection() {
      let message = `<p>Anda akan <strong>MENGAKHIRI BAGIAN INI</strong> dan <strong>BERPINDAH</strong> ke <strong>BAGIAN BERIKUTNYA.</strong><br>Apakah Anda sudah <strong>MEMERIKSA KEMBALI JAWABAN</strong> Anda?</p><hr/>
      <p>You will <strong>END THIS SECTION</strong> and <strong>MOVE</strong> to <strong>NEXT SECTION.</strong><br>Have you <strong>Re-check the answer</strong>?</p>`

      let confirm = `<div><label><input type="checkbox" id="cbx_confirmNextSection"/> Ya, Saya ingin melanjutkan ke bagian berikutnya (Yes, I want to continue to the next section)</label><div id="err_confirmNextSection" style="color:red;"></div></div>`

      this.$buefy.dialog.confirm({
        title: 'Action',
        message: ` ${message}<br>${confirm}`,
        cancelText: 'Return.',
        confirmText: 'Continue',
        type: 'is-success',
        closeOnConfirm: false,
        onConfirm: (val, dialog) => {
          let err_confirmNextSection = document.getElementById(
            'err_confirmNextSection'
          )
          this.isConfirmNextSection = document.getElementById(
            'cbx_confirmNextSection'
          ).checked

          if (this.isConfirmNextSection) {
            dialog.close()
            this.nextSection()
          } else {
            err_confirmNextSection.innerText =
              'Silahkan "checklist" pernyataan di atas sebagai konfirmasi Anda akan melanjutkan ke bagian berikutnya (Please "Checklist" The statement above as confirmation you will proceed to the next section).'

            setTimeout(() => {
              err_confirmNextSection.innerText = ''
            }, 5000)
          }
        },
        onCancel: () => this.timeoutEnableNextBtn(1),
      })
    },
    // For next section
    nextSection() {
      this.pageScrollTop()
      if(this.errMetrix){
        this.danger(
          'Terdapat jawaban soal yang belum berhasil disimpan, silahkan periksa kembali dan pastikan soal yang anda jawab sudah tersimpan dengan baik sebelum melanjutkan ke halaman berikutnya (There are answers to the questions that have not been successfully saved, please check again and make sure the questions you answer are stored properly before lending to the next page)'
        )
        this.timeoutEnableNextBtn()
        return
      }

      if (this.activeStep == 0) {
        if (this.file != null || this.photoStart == 'off') this.activeStep += 1
        else
          this.danger(
            'Silahkan mengambil foto sebelum melanjutkan ke sesi berikutnya (Please take a photo before continuing to the next session)'
          )
        this.timeoutEnableNextBtn()
      } else {
        if (this.arrayConnectionFailed.length) {
          this.danger(
            'Terdapat jawaban soal yang belum berhasil disimpan, silahkan periksa kembali dan pastikan soal yang anda jawab sudah tersimpan dengan baik sebelum melanjutkan ke halaman berikutnya (There are answers to the questions that have not been successfully saved, please check again and make sure the questions you answer are stored properly before lending to the next page)'
          )
          this.timeoutEnableNextBtn()
        } else if (
          this.currentSection != undefined &&
          this.currentSection.questionList.length
        ) {
          this.$refs['formSection-' + this.activeStep][0]
            .validate()
            .then(success => {
              if (success) {
                let totalMandatoryUnanswered = this.currentSection.questionList.filter(
                  question => question.isRequired && !question.hasAnswer
                ).length

                let totalMarkedQuestions = this.currentSection.questionList.filter(
                  question => question.checkLater
                ).length

                if (totalMandatoryUnanswered > 0) {
                  this.danger(
                    'Ada soal yang Anda lewati. Kerjakan dulu soal tersebut untuk dapat melanjutkan proses ke halaman berikutnya <em>(There are questions that you pass. Please make sure you have answer the question before continue the process to the next page)</em>.'
                  )
                  this.timeoutEnableNextBtn()
                } else if (totalMarkedQuestions > 0) {
                  this.warning(
                    'Ada soal yang Anda beri tanda untuk di <b>"Periksa lagi nanti"</b>. Pastikan anda sudah memeriksanya dan silahkan di <b>"uncheck"</b> agar dapat melanjutkan ke halaman selanjutnya <em>(There is a question that you give a sign to <b>"Check again later"</b>. Make sure you have checked it and please <b>"uncheck"</b> In order to be able to proceed to the next page)</em>.'
                  )
                  this.timeoutEnableNextBtn()
                  this.$refs.sidebarInfoProgress.openSidebar()
                } else {
                  if (this.isLastSubsection) this.loadNextSection()
                  else {
                    this.activeStep += 1
                    this.timeoutEnableNextBtn()
                  }
                }
              } else {
                this.danger(
                  'Ada soal yang Anda lewati. Kerjakan dulu soal tersebut untuk dapat melanjutkan proses ke halaman berikutnya <em>(There are questions that you pass. Please make sure you have answer the question before continue the process to the next page)</em>.'
                )
                this.timeoutEnableNextBtn()
              }
            })
        } else {
          if (this.isLastSubsection) this.loadNextSection()
          else {
            this.activeStep += 1
            this.timeoutEnableNextBtn()
          }
        }
      }
    },

    async loadNextSection(skipValidation = false) {
      try {
        this.isLoadingSection = true
        this.isCountDownPaused = true

        this.autoSaveTime()

        // force confirmed for section intro
        if (this.activeStep - 1 == 0) this.isConfirmNextSection = true

        let params = {
          nit: this.nit,
          tesId: this.tesId,
          isPreview: this.isPreview,
          isConfirm: this.isConfirmNextSection,
          skipValidation: skipValidation,
        }
        let currentActiveStep = this.activeStep
        let response = null

        if(this.isLastSection){
          if(this.currentSection != undefined && this.currentSection.isUseTime){
            response = await this.validateAllSectionTimer(params)
            if(response.data.message) this.danger(response.data.message)
          }
        }else{
          response = await this.fetchNextSection(params)
        }

        this.activeStep = 0
        if (response !== null && response.data.sectionList.length) {
          if (this.isLastSection) this.danger(response.data.message)

          this.setupSection(response.data)
          if(response.data.sectionConfirmed){
            if(!this.isLastSection){
              // load next section when current section is already confirmed completed by the user
              this.loadNextSection(skipValidation)

            }else{
              // check once more to make sure there is no section timer skipped
              if(this.currentSection != undefined && this.isSectionUseTimer()){
                response = await this.validateAllSectionTimer(params)

                if(response.data.sectionList.length){
                  this.setupSection(response.data)
                  if(response.data.message) this.danger(response.data.message)

                }else this.activeStep = this.listSection.length + 1

              }else{
                // forced test complete
                this.activeStep = this.listSection.length + 1
              }
            }
          }
        } else {
          this.activeStep = currentActiveStep + 1
        }
      } catch (error) {
        let response = error.response
        this.danger(response.data.message)
      } finally {
        this.timeoutEnableNextBtn()
        setTimeout(() => {
          this.isLoadingSection = false
          this.isCountDownPaused = false
          this.isConfirmNextSection = false
          this.pageScrollTop()
          this.nitWatermark =
            "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'>" +
            "<text transform='translate(20, 100) rotate(-45)' fill='rgb(245, 242, 242)' font-size='20' >[" +
            this.nit +
            ']</text></svg>")'

          const collections = document.getElementsByClassName('watermark')
          for (var i = 0; i < collections.length; i++) {
            if (collections[i]) {
              collections[i].style.backgroundImage = this.nitWatermark
            }
          }
        }, 2 * 1000)
      }
    },

    setupSection(data){
      this.listSection = data.sectionList
      this.sectionIdx = data.sectionIndex
      this.seconds = parseInt(data.sectionList[0].time)
      this.activeStep = 1
      this.resetFormSection()
    },

    isSectionUseTimer(){
      let sections = this.listSection.filter(item => item.isUseIntroduction == 0 && item.isUseTime == 1)
      return sections.length > 0
    },

    resetFormSection() {
      this.listSection.forEach((item, i) => {
        let ref = this.$refs['formSection-' + (i + 1)]

        if (ref != undefined && ref[0] != undefined) ref[0].reset()
      })
    },

    timeoutEnableNextBtn(seconds = 2) {
      setTimeout(() => {
        this.disableNextBtn = false
        this.isConfirmNextSection = false
      }, seconds * 1000)
    },

    getEnvironment() {
      return process.env.VUE_APP_ENVIRONMENT
    },
    // For fetch data
    async fetchData() {
      this.isLoading = true
      let preview = this.isPreview ? 'preview' : 'not preview'
      let data = {}

      if (!this.isPreview) {
        data = {
          code: this.assessmentCode,
          nit: this.nit,
          data: this.$route.params.encryptedData,
          preview: preview,
        }
      } else {
        data = {
          nit: this.nit,
          code: this.assessmentCode,
          name: this.$route.query.nama,
          appUrl: this.$route.query.app_url,
          email: this.$route.query.email,
          cid: this.$route.query.cid,
          uid: this.$route.query.uid,
          photo_start: this.$route.query.photo_start,
          hidden_photo: this.$route.query.hidden_photo,
          hidden_screen_capture: this.$route.query.hidden_screen_capture,
          exit_page: this.$route.query.exit_page,
          tesId: Number(this.tesId),
          preview: preview,
        }
      }

      await this.fetchToken(data)
        .then(response => {})
        .catch(error => {
          let response = error.response
          this.isFailedLoadingPage = true
          this.failedLoadingPageMessage = error.message
          if (response.data.message != undefined)
            this.failedLoadingPageMessage = `${response.data.message} (${response.status})`
        })

      await this.fetchAnswers(data)
        .then(response => {
          this.listSection = []

          if (this.isMobileTablet()) {
            if (
              response.data.photoStart == 'on' &&
              response.data.hiddenPhoto == 'on' &&
              response.data.hiddenScreenCapture == 'on'
            ) {
              throw new Error(
                'Kami menemukan bahwa Anda menggunakan perangkat smartphone. Mohon ganti perangkat Anda menjadi laptop yang dilengkapi dengan kamera (We found that you use a smartphone device. Please change your device to a laptop equipped with a camera).'
              )
            }
          }

          this.watermark = response.data.watermark
          this.listScreens =
            response.data.screens.length > 0
              ? response.data.screens.map(e => e.screen)
              : []
          this.screencaptureTime = response.data.screencaptureTime
          this.screenshotTime = response.data.screenshotTime
          this.thankYouPage.title = response.data.thankYouPageTitle
          this.thankYouPage.description = response.data.thankYouPageDescription
          this.openTab = response.data.openTab
          this.userName = response.data.userName
          this.appUrl = response.data.appUrl
          this.photoStart = response.data.photoStart
          this.hiddenPhoto = response.data.hiddenPhoto
          this.hiddenScreenCapture = response.data.hiddenScreenCapture
          this.exitPage = response.data.exitPage

          this.listSection = response.data.sectionList
          this.sectionIdx = response.data.activeStep
          this.totalSection = response.data.totalSection
          this.isAssessmentFinish = response.data.isFinish
          this.companyCode = response.data.companyCode

          this.backlink =
            'https://' +
            this.appUrl +
            'psikotest?force-complete=' +
            btoa(this.nit + '==' + this.tesId)

          if (this.isAssessmentFinish) {
            this.activeStep = this.listSection.length + 1
          } else {
            if (
              this.currentSection != undefined &&
              this.currentSection.type == 'questionTest' &&
              this.currentSection.isUseTime
            ) {
              this.seconds = this.currentSection.time
            }

            if (this.hiddenScreenCapture != 'off') {
              document.getElementById('btn-share-screen').click()
            }
            if (this.hiddenPhoto != 'off') {
              this.isCameraOpen = true
              document.getElementById('btn-screen-capture').click()

              this.interval = setInterval(() => {
                this.takeScreenShot()
              }, this.screenshotTime * 1000)
            }
            if (this.exitPage != 'off') {
              window.addEventListener('blur', this.storeOpenNewTab)
            }
          }

          this.$buefy.dialog.alert({
            title: 'Perhatian (Attention)',
            message: 'Izinkan untuk beralih ke Layar Penuh (Allow it to switch to the full screen)',
            type: 'is-warning',
            ariaModal: true,
            closeOnConfirm: true,
            canCancel: false,
            onConfirm: () => {
              setTimeout(() => {
                this.startFullScreen()
              }, 500)
            },
          })

          this.isLoading = false
        })
        .catch(error => {
          let response = error.response
          this.isFailedLoadingPage = true
          this.failedLoadingPageMessage = error.message
          if (response.data.message != undefined)
            this.failedLoadingPageMessage = `${response.data.message} (${response.status})`
        })

      this.nitWatermark =
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'>" +
        "<text transform='translate(20, 100) rotate(-45)' fill='rgb(245, 242, 242)' font-size='20' >[" +
        this.nit +
        ']</text></svg>")'

      const collections = document.getElementsByClassName('watermark')
      for (var i = 0; i < collections.length; i++) {
        if (collections[i]) {
          collections[i].style.backgroundImage = this.nitWatermark
        }
      }
    },

    // For modal take photo
    modalTakePhoto() {
      this.isCameraOpen = true
      this.createCameraElement()
      this.isTakePhotoModal = true
    },

    // For create element camera (photo candidat)
    createCameraElement() {
      this.isLoadingCamera = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingCamera = false
          this.$refs.camera.srcObject = stream
        })
        .catch(() => {
          this.isLoadingCamera = false
          this.isCameraOff = true
          alert('Kamera tidak ditemukan (Camera not found)')
        })
    },

    // For store hidden camera
    createHiddenCameraElement() {
      this.isLoadingCamera = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingCamera = false
          this.$refs.cameraHidden.srcObject = stream
        })
        .catch(() => {
          this.isLoadingCamera = false
          this.isCameraOff = true
          alert('Kamera tidak ditemukan')
        })
    },

    // For stop camera stream
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },

    // For send first photo of candidat
    async sendCandidatPhoto() {
      let formData = new FormData()
      formData.append('image', this.file)
      formData.append('nit', this.nit)
      formData.append('assessmentCode', this.assessmentCode)
      formData.append('companyCode', this.companyCode)
      await this.candidatPhoto(formData)
    },

    updateTimer() {
      if (
        this.currentSection != undefined &&
        this.currentSection.type == 'questionTest'
      ) {
        if (this.currentSection.isUseTime) {
          this.isCountDown = true
          this.seconds = this.currentSection.time
          this.timerInterval = setInterval(() => this.timer(), 1000)
        } else {
          this.isCountDown = false
          this.seconds = 0
          this.countdown = ''
          clearInterval(this.timerInterval)
        }
      } else {
        this.isCountDown = false
        this.seconds = 0
        this.countdown = ''
        clearInterval(this.timerInterval)
      }
    },

    // For timer
    timer() {
      if (this.isCountDownPaused) return

      var hoursLeft = Math.floor(this.seconds - 0 * 86400)
      var hours = Math.floor(hoursLeft / 3600)
      var minutesLeft = Math.floor(hoursLeft - hours * 3600)
      var minutes = Math.floor(minutesLeft / 60)
      var remainingSeconds = this.seconds % 60
      this.countdown =
        this.pad(hours) +
        ':' +
        this.pad(minutes) +
        ':' +
        this.pad(remainingSeconds)

      if (this.seconds > 0) {
        this.seconds = this.seconds - 1
      } else {
        clearInterval(this.timerInterval)
        this.seconds = 0
        this.countdown = ''

        if (!this.isLastPage) this.loadNextSection(true)
        else {
          this.autoSaveTime()
          this.activeStep += 1
        }
      }
    },

    // For pad
    pad(n) {
      return n < 10 ? '0' + n : n
    },

    // For auto save
    async autoSave(data) {
      this.errMetrix = data.errMatrix ?? false;
      if (this.activeStep >= 1) {
        let validAnswerId = false
        if (Array.isArray(data.newAnswerId) && data.newAnswerId.length > 0) {
          validAnswerId = true
        } else if (![null, '', 0].includes(data.newAnswerId)) {
          validAnswerId = true
        }

        let question = undefined
        if (this.currentSection != undefined) {
          question = this.currentSection.questionList.find(
            question => question.id == data.newQuestionId
          )

          if (question != undefined) {
            if (question.isRequired) {
              let fileMedias = question.files.filter(file =>
                ['video', 'audio'].includes(file.fileType)
              )
              if (fileMedias.length > 0) {
                if (
                  fileMedias.filter(file => file.totalPlayedVideo > 0).length ==
                  0
                ) {
                  let answerComponent = this.$refs[
                    question.questionType + '_' + question.id
                  ][0]
                  if (typeof answerComponent.resetAnswer == 'function') {
                    setTimeout(() => {
                      answerComponent.resetAnswer()
                    }, 1000)
                  }
                  this.danger(
                    'Silahkan putar video/audio lebih dulu sebelum menjawab soal <em>(Please play the video/audio first before answering the question)</em>'
                  )
                  validAnswerId = false
                }
              }
            }
          }
        }

        if (!validAnswerId || this.isLoadingSection) return

        let form = {
          nit: this.nit,
          code: this.assessmentCode,
          tesId: Number(this.tesId),
          newSectionId: data.newSectionId,
          newQuestionId: data.newQuestionId,
          newAnswerId: data.newAnswerId,
          description: data.description,
          rating: data.rating,
          indexQuestion: data.indexQuestion,
          indexAnswer: data.indexAnswer,
          column: data.column,
          row: data.row,
          time: this.seconds,
        }

        try {
          if (question != undefined) this.sendingAnswerProgress(question, true)

          let response = await this.updateAnswer(form)

          if (question != undefined) {
            question.hasAnswer = response.data.hasAnswer
            if (response.data.status) {
              this.sendingAnswerProgress(question, null)
            } else {
              this.closeConnectionFailedDialog(form)
              if (question != undefined)
                this.sendingAnswerProgress(question, false)
            }
          }
        } catch (err) {
          this.closeConnectionFailedDialog(form)
          if (question != undefined) this.sendingAnswerProgress(question, false)
        }
      }
    },

    async saveCheckLaterQuestion(data, forceUncheck = false) {
      if (forceUncheck) data.checkLater = false
      else data.checkLater = !data.checkLater
      let form = {
        nit: this.nit,
        code: this.assessmentCode,
        tesId: Number(this.tesId),
        newSectionId: this.currentSection.id,
        newQuestionId: data.id,
        time: this.seconds,
        checkLater: data.checkLater,
      }

      if (this.activeStep >= 1) {
        await this.updateCheckLaterQuestion(form)
      }
    },

    closeConnectionFailedDialog(form) {
      this.isCountDownPaused = true
      this.isLoadingConnection = false
      if (form !== 'reload') {
        let formExist = this.arrayConnectionFailed.find(item => {
          return (
            item.newQuestionId == form.newQuestionId &&
            item.row == form.row &&
            item.column == form.column
          )
        })
        if (formExist != undefined) {
          let idx = this.arrayConnectionFailed.indexOf(formExist)
          this.$delete(this.arrayConnectionFailed, idx)
        }

        this.arrayConnectionFailed.push(form)
      }

      if (this.dialogConnectionFailed == false) {
        this.dialogConnectionFailed = true

        this.$buefy.dialog.alert({
          title: 'Connection Failed',
          // confirmText: 'Kirim Jawaban',
          message:
            'Jawaban gagal disimpan, pastikan tidak ada gangguan pada koneksi internet kamu (The answer failed to be saved, make sure there is no disturbance to your internet connection)',
          type: 'is-danger',
          ariaModal: true,
          closeOnConfirm: true,
          canCancel: false,
          onConfirm: () => {
            setTimeout(() => {
              this.dialogConnectionFailed = false
            }, 3000)
            // this.saveConnectionFailed()
          },
        })
      }
    },

    // For save connection failed
    /* async saveConnectionFailed() {
      if (!this.isPreview) {
        this.dialogOpenTab = false
        this.isLoadingConnection = true
        this.dialogConnectionFailed = false

        if (this.activeStep >= 1) {
          try {
            await this.updateAnswerConnectionFailed(form)
            this.arrayConnectionFailed = []
            this.isLoadingConnection = false
            this.$buefy.toast.open({
              message: 'Answer saved successfully',
              type: 'is-success',
            })

            this.isCountDownPaused = false
          } catch (err) {
            this.closeConnectionFailedDialog('reload')
          }
        }
      }
    }, */

    async resendAnswer(question) {
      let form = this.arrayConnectionFailed.filter(item => {
        return item.newQuestionId == question.id
      })

      if (form.length) {
        try {
          this.sendingAnswerProgress(question, true)

          let response = await this.updateAnswerConnectionFailed(form)
          response.data.forEach(item => {
            if (item.status) {
              question.hasAnswer = item.hasAnswer
              this.sendingAnswerProgress(question, null)
            } else this.sendingAnswerProgress(question, false)
          })
          this.autoSaveTime()
        } catch (error) {
          this.sendingAnswerProgress(question, false)
        }
      }
    },

    // flag = true (sending), false (failed), null (success)
    sendingAnswerProgress(question, flag) {
      if (flag === true) {
        question.isSendingAnswer = true
        question.sendingAnswerMessage = 'Menyimpan jawaban (Storing answers)...'
      } else if (flag === false) {
        question.isSendingAnswer = false
        question.sendingAnswerMessage = `Gagal menyimpan jawaban (Failed to store the answer).`
      } else {
        // flag === null
        this.isCountDownPaused = false

        question.isSendingAnswer = null
        question.sendingAnswerMessage = 'Jawaban telah disimpan (The answer has been stored).'

        let failed = this.arrayConnectionFailed.filter(item => {
          return item.newQuestionId == question.id
        })

        failed.forEach(item => {
          let idx = this.arrayConnectionFailed.indexOf(item)
          this.$delete(this.arrayConnectionFailed, idx)
        })
        setTimeout(() => {
          question.sendingAnswerMessage = null
        }, 3 * 1000)
      }
    },

    async autoSaveTime() {
      if (this.currentSection != undefined && this.currentSection.isUseTime) {
        let form = {
          nit: this.nit,
          code: this.assessmentCode,
          tesId: Number(this.tesId),
          sectionId: this.currentSection.id,
          time: this.seconds,
        }

        await this.updateTime(form)
      }
    },

    // For store proctoring record
    async storeProctoringRecord(action, event) {
      if (!this.isPreview) {
        let content = {
          nit: this.nit,
          code: this.assessmentCode,
          tesId: Number(this.tesId),
          action: action,
          text: null,
          target: null,
        }

        if (action == 'copy') {
          content.text = document.getSelection().toString()
        } else if (action == 'paste') {
          content.text = event.clipboardData.getData('text')
          content.target = event.target
        } else if (action == 'screen-shot') {
          content.text =
            this.currentSection != undefined
              ? this.currentSection.title
              : 'active-step ' + this.activeStep
        }

        document.body.style.cursor = 'progress'
        await this.$store
          .dispatch('newAnswer/storeProctoringRecord', content)
          .then(() => (document.body.style.cursor = 'default'))
      }
    },
    // For store open new tab
    storeOpenNewTab() {
      if (this.activeStep > 0) {
        if (this.dialogOpenTab == false) {
          if (this.openTab === null) {
            this.openTab = 0
          }
          this.dialogOpenTab = true
          this.openTab++
          this.openTab_++

          let message =
            ' PERINGATAN Anda telah meninggalkan tab halaman Psikotes sebanyak ' +
            this.openTab_ +
            ' kali' +
            ' (WARNING! You have left this Page ' +
            this.openTab_ +
            ' times'

          if ([3, 4].includes(this.openTab_)) {
            message =
              '<strong>PEMBERITAHUAN:</strong> Anda telah terdeteksi <strong>' +
              this.openTab_ +
              'x</strong> keluar dari halaman pengerjaan Psikotes. Akun Anda akan ter-logout secara otomatis jika masih keluar masuk halaman ' +
              (5 - this.openTab_) +
              'x lagi. Jika terjadi, pastikan kembali tidak ada gangguan selama Anda mengerjakan tes' +
              '<hr/>' +
              '<strong>ANNOUNCEMENT:</strong> You have been detected <strong>' +
              this.openTab_ +
              'x</strong> out of the psychometric testing page. Your account will be logged out automatically if it is still in and out of the page ' +
              (5 - this.openTab_) +
              'x again. If it happens, make sure there is no disturbance again while you are doing the test'
          } else if (this.openTab_ >= 5) {
            message =
              '<strong style="color: orange;">PEMBERITAHUAN:</strong> Anda telah terdeteksi <strong>' +
              this.openTab_ +
              'x</strong> keluar dari halaman pengerjaan Psikotes. Pastikan kembali tidak ada gangguan selama Anda mengerjakan tes, dan <strong>silakan login kembali</strong>.' +
              '<hr/>' +
              '<strong style="color: orange;">ANNOUNCEMENT:</strong> You have been detected <strong>' +
              this.openTab_ +
              'x</strong> out of the psychometric testing page. Make sure there is no disturbance again while you are doing the test, dan <strong>Please log in again</strong>.'
          }

          this.autoSaveTime()
          this.$buefy.dialog.alert({
            title: 'Warning',
            message: message,
            type: 'is-warning',
            ariaModal: true,
            onConfirm: async () => {
              try {
                let form = {
                  nit: this.nit,
                  code: this.assessmentCode,
                  tesId: Number(this.tesId),
                  openTab: this.openTab,
                }
                if (!this.isPreview)
                  await this.$store.dispatch('newAnswer/storeOpenNewTab', form)
              } catch (error) {
                console.log('failed store open new tab', error)
              } finally {
                this.dialogOpenTab = false

                if (this.openTab_ >= 5 && !this.isPreview) {
                  location.replace('https://' + this.appUrl + 'logout')
                }
              }
            },
          })
        }
      }
    },

    requestFullScreen(element) {
      // Supports most browsers and their versions.
      var requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen

      if (requestMethod) {
        // Native full screen.
        requestMethod.call(element)
      } else if (typeof window.ActiveXObject !== 'undefined') {
        // Older IE.
        var wscript = new ActiveXObject('WScript.Shell')
        if (wscript !== null) {
          wscript.SendKeys('{F11}')
        }
      }
    },

    // For take photo
    async takePhoto() {
      this.file = null
      this.isProcessPhoto = false
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true

        const FLASH_TIMEOUT = 50

        setTimeout(() => {
          this.isShotPhoto = false
        }, FLASH_TIMEOUT)
      }

      const context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5)
      this.file = document.getElementById('photoTaken').toDataURL('image/jpeg')

      this.isProcessPhoto = true
      this.isTakePhotoModal = false
      await this.stopCameraStream()
    },

    // For take screen shot
    async takeScreenShot() {
      if (!this.isPreview) {
        let canvas = document.getElementById('hiddenCanvas')
        let context = canvas.getContext('2d')
        context.drawImage(this.$refs.cameraHidden, 0, 0, 450, 337.5)

        let formData = new FormData()
        formData.append(
          'image',
          this.nit + '||' + canvas.toDataURL('image/jpeg')
        )
        formData.append('nit', this.nit)
        formData.append('assessmentCode', this.assessmentCode)
        formData.append('companyCode', this.companyCode)
        await this.storeHiddenPhoto(formData)
          .then(response => {
            this.failedHiddenCamera = 0
          })
          .catch(error => {
            if (this.failedHiddenCamera >= 5) {
              clearInterval(this.interval)
              this.$buefy.dialog.alert({
                title: 'Warning',
                message:
                  'Sistem gagal mengambil foto, sepertinya kamera pada device Anda terhalang atau hasil foto dari kamera Anda tidak terlihat jelas. Klik OK untuk memuat ulang halaman ini <em>(The system fails to take photos, it looks like the camera on your device is blocked or the photos from your camera are not clear.Click OK to reload this page)</em>.',
                type: 'is-warning',
                ariaModal: true,
                onConfirm: async () => location.reload(),
              })
            } else {
              this.failedHiddenCamera++
              console.log('Trying to take another photo in 10s...')
              setTimeout(() => {
                this.takeScreenShot()
              }, 10000)
            }
          })
      }
    },

    // For start recording

    async startRecording() {
      const displayMediaOptions = {
        video: {
          mediaSource: 'screen',
          cursor: 'always',
        },
        videoBitsPerSecond: 1048576,
        audio: false,
      }
      this.clickShareScreen += 1
      await navigator.mediaDevices
        .getDisplayMedia(displayMediaOptions)
        .then(async stream => {
          let videoTrack = stream.getVideoTracks()[0]
          let deviceId = videoTrack.getSettings().deviceId
          let width = videoTrack.getSettings().width
          let height = videoTrack.getSettings().height
          let displaySurface = null
          let url = `https://api.whatsapp.com/send?phone=+6287877458775&text=Halo%2C%20saya%20membutuhkan%20bantuan%20dengan%20kendala%20berbagi%20layar.%20%0ANIT%3A%20${this.nit}`
          let waLink = `<a href="${url}" target="_blank" class="has-text-success" style="text-decoration: underline;">+6287877458775</a>`
          let ssProblemMsg = `Perangkat yang Anda gunakan saat ini tidak kompatibel dengan sistem pengawasan tes yang diaktifkan. Kami mohon untuk menghubungi IT Support melalui nomor berikut: ${waLink}, agar kami dapat membantu Anda.`
          this.errorShareScreen = `deviceId:'${deviceId}', width:'${width}', height:'${height}', `

          if (videoTrack.label != undefined) displaySurface = videoTrack.label
          else displaySurface = videoTrack.getSettings().displaySurface

          if (displaySurface == undefined) {
            stream.getTracks().forEach(track => track.stop())
            throw 'Sepertinya ada kendala pada browser yang Anda gunakan, silahkan beralih ke salah satu browser berikut: Google Chrome, Microsoft Edge, Opera, Brave, atau Mozilla Firefox.<br>Pastikan Anda menggunakan versi browser yang terbaru (It seems that there are obstacles in the browser that you are using, please switch to one of the following browsers: Google Chrome, Microsoft Edge, Opera, Brave, or Mozilla Firefox. <br> Make sure you use the latest browser version).'
          } else if (!displaySurface.trim().includes('screen')) {
            if (
              displaySurface.trim().includes('window') ||
              displaySurface.trim().includes('web-contents-media-stream')
            ) {
              let message =
                'Silahkan pilih "Entire Screen" atau "Seluruh Layar" untuk melanjutkan (Please select "Entire Screen" or "All Screen" to continue).'

              message += `<br><video style="width:100%; margin-top:10px;" height="" muted autoplay loop>
                                <source src="https://hcc.sgp1.cdn.digitaloceanspaces.com/documents/Allow%20browser%20to%20share%20entire%20screen.mp4" type="video/mp4" />
                            </video>`

              this.$buefy.dialog.alert({
                title: 'Warning',
                message: message,
                type: 'is-warning',
                ariaModal: true,
                onConfirm: () => {
                  document.getElementById('btn-share-screen').click()
                  window.location.reload(true)
                },
              })
            }
          }else if (displaySurface.trim().includes('screen')){
            let splitDisplay = displaySurface.trim().split(":");
            if(splitDisplay.length > 2){
              if(this.isMac()){
                // doing check external monitor
                let formDataScreen = new FormData()
                formDataScreen.append('screen', displaySurface.trim())
                await this.storeScreen(formDataScreen);
              }else{
                if(splitDisplay[1] != 0){
                  let msgExtDisplay = "Anda terdeteksi menggunakan Layar Tambahan. Silahkan gunakan Layar Utama perangkat Anda untuk melanjutkan pengerjaan tes (You are detected using an additional screen. Please use the main screen of your device to continue working on the test).";
                  this.$buefy.dialog.alert({
                    title: 'Layar Tambahan Terdeteksi (Additional screens are detected)',
                    message: msgExtDisplay,
                    type: 'is-warning',
                    ariaModal: true,
                    canCancel: false,
                    onConfirm: () => {
                      window.location.reload(true)
                    },
                  })
                }else{
                  let formDataScreen = new FormData()
                  formDataScreen.append('screen', displaySurface.trim())
                  await this.storeScreen(formDataScreen);
                }
              }
            }
          }

          stream.getVideoTracks()[0].onended = () => {
            clearInterval(this.ssInterval)

            let message =
              'Silahkan aktifkan fitur "Share screen" atau "Bagikan Layar" pada browser anda untuk melanjutkan (Please activate the "Share Screen" or "Sharing Screen" feature in your browser to continue).'

            if (this.isMac()) {
              if (this.isBrowserSafari()) {
                message =
                  'Silahkan aktifkan fitur "Allow To Share Screen" atau "Izinkan untuk Berbagi Layar" pada browser anda untuk melanjutkan (Please activate the "Allow to Share Screen" or "Allowing to Share Screen" feature).'
              }

              let url =
                'https://hcc.sgp1.cdn.digitaloceanspaces.com/documents/Instruksi%20Share%20Screen%20Macbook.pdf'
              message += `<br><a href="${url}" target="_blank" class="has-text-success" style="text-decoration: underline;">Pengguna Mac/Apple? Ikuti instruksi berikut (Mac/Apple users?Follow the following instructions) >></a>`
            }

            this.$buefy.dialog.alert({
              title: 'Warning',
              message: message,
              type: 'is-warning',
              ariaModal: true,
              onConfirm: () => {
                document.getElementById('btn-share-screen').click()
                window.location.reload(true)
              },
            })
          }

          const video = document.getElementById('video')
          video.onloadedmetadata = () => video.play()
          video.srcObject = stream
          this.captureSS()
        })
        .catch(error => {
          console.log(error.name, error)
          this.errorShareScreen += "message:'" + error + "'"

          let url = `https://api.whatsapp.com/send?phone=+6287877458775&text=Halo%2C%20saya%20membutuhkan%20bantuan%20dengan%20kendala%20berbagi%20layar.%20%0ANIT%3A%20${this.nit}`
          let waLink = `<a href="${url}" target="_blank" class="has-text-success" style="text-decoration: underline;">+6287877458775</a>`
          let ssProblemMsg = `Perangkat yang Anda gunakan saat ini tidak kompatibel dengan sistem pengawasan tes yang diaktifkan. Kami mohon untuk menghubungi IT Support melalui nomor berikut: ${waLink}, agar kami dapat membantu Anda.`

          let message =
            'Sepertinya device Anda tidak mengijinkan platform untuk mengaktifkan "Proctoring Berbagi Layar". Silahkan coba untuk reload/refresh browser Anda atau gunakan device lain untuk melanjutkan (Looks like your device does not allow the platform to activate the "screen sharing proctoring".Please try to reload/refresh your browser or use another device to continue).'

          if (
            [
              'NotAllowedError',
              'InvalidStateError',
              'InvalidAccessError',
            ].includes(error.name)
          ) {
            if (this.clickShareScreen >= 2) {
              this.storeMonitoringProctoSS()
              message = ssProblemMsg
            } else {
              message =
                'Silahkan aktifkan fitur "Share screen" atau "Bagikan Layar" pada browser anda untuk melanjutkan (Please activate the "Share Screen" or "Sharing Screen" feature in your browser to continue).'

              if (this.isMac()) {
                if (this.isBrowserSafari()) {
                  message =
                    'Silahkan aktifkan fitur "Allow To Share Screen" atau "Izinkan untuk Berbagi Layar" pada browser anda untuk melanjutkan (Please activate the "Allow to Share Screen" or "Allowing to Share Screen" feature).'
                }
              }
            }
          } else if (error.name == undefined && typeof error == 'string') {
            message = error
          }

          if (this.isMac() && this.clickShareScreen < 2) {
            let url =
              'https://hcc.sgp1.cdn.digitaloceanspaces.com/documents/Instruksi%20Share%20Screen%20Macbook.pdf'

            // message += `<br><a href="${url}" target="_blank" class="has-text-success" style="text-decoration: underline;">Pengguna Mac/Apple? Ikuti instruksi berikut >></a>`
            message += `<br><video style="width:100%; margin-top:10px;" height="300" muted autoplay loop>
                                <source src="https://hcc.sgp1.cdn.digitaloceanspaces.com/documents/Set%20permission%20on%20Mac%20to%20allow%20browser%20to%20share%20entire%20screen.mp4" type="video/mp4" />
                            </video>`
          } else {
            this.storeMonitoringProctoSS()
            message = ssProblemMsg
          }

          this.$buefy.dialog.alert({
            title: 'Warning',
            message: message,
            type: 'is-warning',
            ariaModal: true,
            onConfirm: () => {
              document.getElementById('btn-share-screen').click()
              // window.location.reload(true)
            },
          })
        })
    },

    // for capture screen capture
    async captureSS() {
      this.ssInterval = setInterval(async () => {
        var canvas = document.createElement('canvas')
        canvas.width = 1280
        canvas.height = 720
        var ctx = canvas.getContext('2d')
        var canvasBlur = document.createElement('canvas')
        canvasBlur.width = 256
        canvasBlur.height = 144
        var ctxBlur = canvasBlur.getContext('2d')

        // Draw video on canvas
        const video = document.getElementById('video')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        ctxBlur.drawImage(video, 0, 0, canvasBlur.width, canvasBlur.height)

        this.gBlur(3, canvasBlur, ctxBlur);

        const img = canvas.toDataURL('image/jpeg')
        const imgBlur = canvasBlur.toDataURL('image/jpeg')
        await this.storeHidenScreenShot(img, imgBlur)
      }, this.screencaptureTime * 1000)
    },

    gBlur(blur, canvasSelected, ctxSelected) {
      let start = +new Date();
      let canvas = canvasSelected;
      let ctx = ctxSelected;
      
      let sum = 0;
      let delta = 5;
      let alpha_left = 1 / (2 * Math.PI * delta * delta);
      let step = blur < 3 ? 1 : 2;
      
      for (let y = -blur; y <= blur; y += step) {
        for (let x = -blur; x <= blur; x += step) {
          let weight = alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta));
          sum += weight;
        }
      }
      let count = 0;
      for (let y = -blur; y <= blur; y += step) {
        for (let x = -blur; x <= blur; x += step) {
          count++;
          ctx.globalAlpha = alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta)) / sum * blur;
          ctx.drawImage(canvas,x,y);
        }
      }
      ctx.globalAlpha = 1;
    },

    // For store hidden screen capture
    async storeHidenScreenShot(img, imgBlur) {
      let formData = new FormData()
      formData.append('image', imgBlur)
      formData.append('imageNotBlur', img)
      formData.append('nit', this.nit)
      formData.append('assessmentCode', this.assessmentCode)
      formData.append('companyCode', this.companyCode)

      if (!this.isPreview) {
        await this.storeHiddenScreenCapture(formData)
      }
    },

    async storeMonitoringProctoSS() {
      let content = {
        nit: this.nit,
        tesId: Number(this.tesId),
        error: this.errorShareScreen,
      }
      await this.storeMonitoringProctoShareScreen(content)
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
        pauseOnHover: true,
      })
    },

    // FOr danger alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
        duration: 5000,
        pauseOnHover: true,
      })
    },

    warning(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-warning',
        duration: 5000,
        pauseOnHover: true,
      })
    },

    changePage(page) {
      this.currentPage = page
    },
  },
}
</script>

<style lang="scss">
#section-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: progress;
  z-index: 2;

  p {
    color: white;
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
  }
}
h3 {
  font-weight: 400;
  font-size: 28px;
}

.main-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  /* You need to define an explicit height! */
  height: 100vh;
}

.b-steps .steps {
  display: none !important;
}
.jump-button {
  width: 3%;
  margin-top: 1%;
  margin-right: 1%;
}
.header {
  &-mobile {
    overflow: hidden;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin-left: 1%;
    padding-top: 1%;
    height: 10%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }

  &-desktop {
    overflow: hidden;
    background-color: whitesmoke;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    margin-left: 0;
    padding-top: 1%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }
}
.btn-next-mobile {
  padding-top: 6.5%;
  padding-right: 3%;
}
.progress-mobile {
  margin-top: 6%;
}
.pagination-footer {
  overflow: hidden;
  position: fixed;
  background-color: whitesmoke;
  bottom: 0;
  z-index: 1;
  width: 100%;
  margin-left: 1%;
  padding-bottom: 1%;
}
.footer-column {
  margin-left: 0.25rem !important;
}

ul.pagination-list {
  list-style-type: none;
}

span.column.is-12 {
  position: fixed;
  background-color: whitesmoke;
  right: 0rem;
  left: 0rem;
  width: 100%;
  padding: 0.5rem;
  bottom: 0;
  display: flex;
  justify-content: center;
}

@media (min-width: 360px) and (max-width: 1000px) {
  .toast {
    width: 95%;
  }
  .modal-card-foot > button {
    height: auto !important;
    font-size: 1.25rem !important;
    white-space: normal !important;
  }
}
@media (min-width: 360px) {
  .contents {
    padding-top: 11%;
  }
  .step-thank-you {
    padding-top: 7%;
  }
}
@media screen and (min-width: 1280px) {
  .wrapper {
    width: 1280px;
    margin: 0 auto;
    border: 1px solid whitesmoke;
  }
}
@media screen and (min-width: 1024px) {
  .header-desktop {
    width: inherit !important;
  }
  .step-thank-you {
    padding-top: 0;
  }
  .contents {
    padding-top: 4%;
  }
  .bar {
    padding-right: 2.5%;
    padding-top: 1.3%;
  }
  .btn-next {
    padding-top: 0.5%;
    padding-right: 3%;
  }
}
@media screen and (min-width: 1408px) {
  .bar {
    padding-right: 2.5%;
    padding-top: 0.7%;
  }
  .btn-next {
    padding-top: 0.5%;
    padding-right: 2.2%;
  }
}
.navbar.is-spaced {
  padding-right: 2.5%;
  padding-top: 0% !important;
}
.image-background {
  width: 100%;
  height: auto;
  text-align: center;
  color: white;
}
html,
body,
.height {
  height: 100% !important;
  width: 100% !important;
}
.container {
  height: 101.8vh;
}
.btn-take-photo {
  padding-top: 5%;
}
.steps {
  display: none !important;
}

body {
  display: flex;
  justify-content: center;
}
.photo {
  width: 360px;
  padding: 1%;
  border: 1px solid black;
}
.section {
  padding-top: 0.5rem !important;
}
@media screen and (min-width: 1216px) {
  .progress-wrapper {
    /* padding-top: 1%; */
    width: 23%;
  }
}
@media screen and (min-width: 1408px) {
  .progress-wrapper {
    /* padding-top: 1%; */
    width: 25%;
  }
  .progress {
    height: 23px !important;
  }
  .progress-wrapper .progress-value {
    top: 17% !important;
    font-size: 15px !important;
  }
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }
  .question {
    margin-bottom: 20px;
  }
  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
.button.button.is-primary {
  background-color: #00a3a3;
}
ul {
  list-style: disc inside;
}
ol {
  list-style: inside decimal;
}
div.modal-card.animation-content {
  max-width: 860px;
  width: auto;
}

video::-webkit-media-controls {
  display: none;
}
</style>
